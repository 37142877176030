
nav.menu ul {
    list-style: none;
    display: block;
    /*text-align:center;*/
    margin-top: 0.75rem;
    padding: 0;
    max-width: 45rem;
    /* Width is the same as tufte.css body */
    font-size: 0.9rem;
    width: 87.5%;
}

nav.menu li {
    display: inline-block;
    margin-right: 1rem;
}

nav.menu li a {
    text-decoration: none;
    background: transparent;
    color: rgba(65, 70, 75, 1);
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

nav.menu li a:hover,
nav.menu li a:active,
nav.menu li a:focus {
    background: inherit;
    color: darkgray;
}