// VENDOR
@import "vendor/tufte.scss";

// OUR CODE
@import "general";

/// PAGES
@import "pages/footer";

/// COMPONENTS
@import "components/code-highlight";
@import "components/toc";
@import "components/nav";
@import "components/header";
@import "components/meta";

// Look at this https://gohugo.io/hugo-pipes/resource-from-template/#readout

@import "syntax/highlight-light.scss"
