header.brand {
    margin-top: 0.5em;
}

/* Main brand title */
header.brand h1 {
    margin: 0;
    font-weight: 400;
    color: rgba(65, 70, 75, 1);
}

header.brand h2 {
    margin: 0;
    padding-top: 0rem;
    /*font-style: normal;*/
    /*font-weight: 200;*/
    color: rgba(100, 105, 110, 1);
}

header.brand hr {
    text-align: left;
    margin-left: 0;
    width: 75%;
    border-color: rgba(250, 250, 250, 0.25);
}
